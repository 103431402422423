@import '@hh.ru/magritte-ui/tokens';
@import 'src/styles/transitions';

.custom-header-link() {
    .transition_out(color);
    .magritte-typography-label-3-regular();

    color: @magritte-color-component-link-text-neutral;

    svg > path {
        .transition_out(~'fill, stroke');
    }

    &:hover {
        .transition_in(color);

        color: @magritte-color-component-link-text-state-neutral-hovered;

        svg > path {
            .transition_in(~'fill, stroke');

            fill: @magritte-color-component-link-text-state-neutral-hovered;
            stroke: @magritte-color-component-link-text-state-neutral-hovered;
        }
    }

    &:active {
        .transition_in(color);

        color: @magritte-color-component-link-text-state-neutral-hovered;

        svg > path {
            .transition_in(~'fill, stroke');

            fill: @magritte-color-component-link-text-state-neutral-hovered;
            stroke: @magritte-color-component-link-text-state-neutral-hovered;
        }
    }

    &:focus {
        .transition_in(color);

        color: @magritte-color-component-link-text-state-neutral-hovered;

        svg > path {
            .transition_in(~'fill, stroke');

            fill: @magritte-color-component-link-text-state-neutral-hovered;
            stroke: @magritte-color-component-link-text-state-neutral-hovered;
        }
    }
}
