@import '@hh.ru/magritte-ui/tokens';

.menu {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-4-x;
}

.desktop-menu-container {
    width: 272px;
}

.mobile-menu-container {
    padding-bottom: @magritte-core-space-4-x;
}
