@transition-duration-in: 0.2s;
@transition-duration-in-long: 0.4s;
@transition-duration-out: 0.1s;

.transition_in(@property: all, @duration: @transition-duration-in, @delay: 0s, @function: ease-in) {
    transition-property: @property;
    transition-duration: @duration;
    transition-timing-function: @function;
    transition-delay: @delay;
}

.transition_out(@property: all, @duration: @transition-duration-out, @delay: 0s, @function: ease-out) {
    transition-property: @property;
    transition-duration: @duration;
    transition-timing-function: @function;
    transition-delay: @delay;
}
