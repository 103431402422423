@import '@hh.ru/magritte-ui/tokens';
@import 'src/styles/transitions';

@drawer-spacing: @magritte-core-space-3-x;

.drawer {
    .transition_out(right);

    position: fixed;
    top: @drawer-spacing;
    bottom: @drawer-spacing;
    right: -480px;
    width: 480px;
    background: @magritte-color-background-body; // TODO: нет цвета magritte
    border: solid 1px #dce3eb; // TODO: нет цвета magritte
    border-radius: @magritte-static-border-radius-600;
    box-shadow: @magritte-shadow-level-3-x @magritte-shadow-level-3-y @magritte-shadow-level-3-blur
        @magritte-shadow-level-3-spread @magritte-shadow-level-3-color;
    box-sizing: border-box;
}

.drawer_open {
    .transition_in(right);

    right: @drawer-spacing;
}

.drawer-container {
    width: 100%;
    height: 100%;
    padding: @magritte-core-space-6-x;
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-6-x;
    box-sizing: border-box;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-6-x;
}

.header-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: @magritte-core-space-6-x;
}

.header-close {
    cursor: pointer;
}

.header-options {
    display: flex;
    justify-content: flex-end;
    gap: @magritte-core-space-2-x;
}

.drawer-content {
    height: 100%;
    flex-grow: 1;
    overflow: auto;
}

.drawer-buttons {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-2-x;
}

.backdrop {
    .transition_out(opacity);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: @magritte-color-background-overlay;
    opacity: @magritte-semantic-opacity-overlay;
    visibility: hidden;
}

.backdrop_open {
    .transition_in(opacity);

    visibility: visible;
}
