@import '@hh.ru/magritte-ui/tokens';

.nav_mobile {
    width: 100%;

    .nav-list {
        flex-direction: column;
        gap: @magritte-core-space-1-x;
    }
}

.nav-list {
    display: flex;
    align-items: center;
    width: inherit;
}

.nav-item {
    width: inherit;
}
