@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import 'src/styles/font-title-secondary';

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.error {
    .font-title-secondary-3();

    color: @magritte-color-text-primary;
    text-align: center;

    .screen-gt-s({
        .font-title-secondary-1();

        max-width: 600px;
    });
}

.error-description {
    .magritte-typography-paragraph-2-regular();

    color: @magritte-color-text-primary;

    margin-top: 12px;
    text-align: center;
    margin-bottom: 24px;
    .screen-gt-s({
        margin-top: 20px;
        margin-bottom: 40px;
        max-width: 600px;
    });
}

.icon {
    width: 168px;
    height: 84px;
    margin-top: 12px;
    margin-bottom: 40px;
    .screen-gt-s({
        width: 422px;
        height: 211px;
        margin-top: 94px;
        margin-bottom: 100px;
    });
}

.expander {
    flex-grow: 1;
}

.footer {
    .magritte-typography-paragraph-4-regular();

    padding: 0 16px 32px;
    color: @magritte-color-text-secondary;
    text-align: center;
    .screen-gt-s({
        text-align: left;
        padding: 0 108px 60px;
    });
}

.link {
    width: 100%;
}

.button-container {
    width: 100%;
    margin-bottom: 84px;

    .screen-gt-s({
        margin-bottom: 24px;
        width: fit-content;
    });
}
