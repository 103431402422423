@import '@hh.ru/magritte-ui/tokens';
@import 'src/styles/custom-header-link';

.nav-item {
    .custom-header-link();

    display: block;
    padding: 10px 12px;
    white-space: nowrap;
    box-sizing: border-box;
}

.nav-item_selected {
    .magritte-typography-subtitle-2-semibold();

    color: @magritte-color-component-link-text-neutral;

    &:hover {
        color: @magritte-color-component-link-text-neutral;
    }
}

.nav-item_mobile {
    .magritte-typography-label-2-regular();

    padding: 10px 16px;

    &.nav-item_selected {
        .magritte-typography-subtitle-1-semibold();
    }
}
