@import '@hh.ru/magritte-ui/tokens';

.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: @magritte-core-space-8-x;
}
